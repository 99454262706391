import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { layers } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Wrapper = styled.div<{ isResizing: boolean }>({
	display: 'flex',
	flex: 1,
	alignItems: 'stretch',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: ({ isResizing }) => (isResizing ? 'ew-resize' : 'default'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SidebarContent = styled.div({
	overflowY: 'auto',
	/* ⚠️ to accommodate this style we place extra div around SidebarIssueApp and CommentsSidebar */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		position: 'absolute',
		top: 0,
		bottom: 0,
		width: '100%',
		padding: `${token('space.100', '8px')} 0`,
	},
	/* Fix for jumping Issue App breadcrumbs on hover. Ideally this should be fixed inside Issue App component */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'nav ol > div': {
		display: 'flex',
		alignItems: 'center',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResizeHandle = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3}px`,
	cursor: 'ew-resize',
	position: 'relative',
	alignSelf: 'stretch',
	zIndex: layers.blanket(),
	left: token('space.negative.150', '-12px'),
	height: '100%',
	'&::after': {
		content: '',
		background: 'none',
		transition: 'background 0.2s',
		position: 'absolute',
		width: '2px',
		top: 0,
		left: token('space.150', '12px'),
		height: '100%',
	},
	'&:hover::after, &:active::after': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.border.focused', colors.B200),
	},
});
