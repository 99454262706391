export type BaseJiraBusinessForm = {
	formId: string;
	title: string;
	description: string;
	bannerColor?: string;
};

export const FormAccessLevelTypes = {
	LIMITED: 'limited',
	OPEN: 'open',
} as const;

export type FormAccessLevelTypes = (typeof FormAccessLevelTypes)[keyof typeof FormAccessLevelTypes];

export type InexactBaseJiraBusinessForm = BaseJiraBusinessForm;

export type CreateFieldConfigProps = {
	mode: 'create' | 'edit';
	fieldId?: string;
} | null;

export type CardDragState =
	| {
			type: 'idle';
	  }
	| {
			type: 'dragging';
			container?: HTMLElement;
	  };
