// 'react-intl-next' is required for ATLAS-68984
// eslint-disable-next-line jira/restricted/react-intl
import { defineMessages } from 'react-intl-next';

export default defineMessages({
	fieldLevelIndicatorTooltipContent: {
		id: 'issue-adjustments.field-busy-indicator.field-level-indicator-tooltip-content',
		defaultMessage: 'Loading UI modifications...',
		description: 'A tooltip message showed on UI modifications loading icon on each loading field',
	},
});
