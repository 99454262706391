import React from 'react';
import { HelperMessage } from '@atlaskit/form';
import { Box, xcss } from '@atlaskit/primitives';
import { isFedRamp } from '@atlassian/atl-context';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { REQUEST_TYPE } from '@atlassian/jira-issue-create-common-types/src/common/constants/graphql-field-types.tsx';
import type { ParentFieldOption } from '@atlassian/jira-issue-field-parent/src/common/types.tsx';
import { ParentFieldEdit } from '@atlassian/jira-issue-field-parent/src/ui/edit/index.tsx';
import { FieldContainer } from '../../common/ui/fields/styled.tsx';
import { withFormField } from '../../common/ui/with-form-field/index.tsx';
import { closeMenuOnScroll } from '../../common/utils/select/index.tsx';
import messages from './messages.tsx';
import type { Props, ParentFieldConfig } from './types.tsx';
import { validateParentField, transformDefaultParentValue } from './utils.tsx';

export const ParentField = (props: Props) => {
	const {
		fieldId,
		fieldProps: { value, onChange },
		width,
		autoFocus = false,
		onCloseMenuOnScroll = closeMenuOnScroll,
		isDropdownMenuFixedAndLayered = false,
		isExistingIssue,
		selectedProject,
		selectedIssueType,
		issueKey,
		isReadOnly = false,
	} = props;

	const { formatMessage } = useIntl();

	let issueTypeId: string | undefined;
	if (selectedIssueType) {
		issueTypeId =
			selectedIssueType.type === REQUEST_TYPE
				? selectedIssueType.issueTypeId
				: selectedIssueType.value;
	}

	/**
	 * When creating a sub-task from the GIC modal, we:
	 * - pre-populate the parent field in "src/packages/issue-navigator/issue-operations/meatball-menu/src/ui/dropdown-menu-group/button-item/index"
	 * - disable the parent field to prevent the user to change it
	 *
	 * This prevents the user to make a mistake or bump into potential edge cases.
	 */
	const isDisabled = props.isSubTaskCreationOpen || isReadOnly;

	return (
		<FieldContainer width={width}>
			<ParentFieldEdit
				fieldId={fieldId}
				value={value}
				onChange={onChange}
				autoFocus={autoFocus}
				isDisabled={isDisabled}
				isExistingIssue={isExistingIssue}
				onCloseMenuOnScroll={onCloseMenuOnScroll}
				isDropdownMenuFixedAndLayered={isDropdownMenuFixedAndLayered}
				selectedProjectId={selectedProject ? selectedProject.value : undefined}
				selectedIssueTypeId={issueTypeId}
				fetchSuggestionsOnFocus
				passedInIssueKey={
					fg('render-modern-issue-transition') || isFedRamp() ? issueKey : undefined
				}
				spacing="default"
			/>
			<Box xcss={fieldDescriptionStyles}>
				<HelperMessage>
					{formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.descriptionIssueTermRefresh
							: messages.description,
					)}
				</HelperMessage>
			</Box>
		</FieldContainer>
	);
};

const fieldDescriptionStyles = xcss({
	marginTop: 'space.100',
});

export default withFormField({
	transformDefaultValue: transformDefaultParentValue,
	validator: validateParentField,
})<ParentFieldConfig, ParentFieldOption>(
	// @ts-expect-error - TS2345 - Argument of type '(props: Props) => JSX.Element' is not assignable to parameter of type 'AbstractComponent<ComponentProps<ParentFieldConfig, LegacyValue<Option>>, any>'.
	ParentField,
);
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { mutateParentField } from './utils';
