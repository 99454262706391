import { useMemo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';

export const useConditionalFieldLogicAnalyticsAttrs = () =>
	useMemo(
		() => ({
			isConditionalFieldLogicGateEnabled: fg('jira_forms_conditional_field_logic_gate_'),
		}),
		[],
	);
