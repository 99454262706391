import React, { memo } from 'react';
import {
	useAdjustmentsEnabled,
	isFieldTypeSupported,
} from '@atlassian/jira-issue-adjustments/src/controllers.tsx';
import { GIC_VIEW_TYPE } from '../../common/constants/index.tsx';
import { EnabledFieldValueDecorator } from './enable-field-value-decorator/index.tsx';
import type { EnabledFieldValueDecoratorProps } from './enable-field-value-decorator/types.tsx';

export type FieldValueDecoratorProps = EnabledFieldValueDecoratorProps<unknown, string | undefined>;

const FieldValueDecoratorAsyncNoMemo = ({
	children,
	fieldType,
	fieldProps,
	fieldId,
}: FieldValueDecoratorProps) => {
	const adjustmentsAreEnabled = useAdjustmentsEnabled();

	const undecoratedResult = children(fieldProps);

	if (isFieldTypeSupported(GIC_VIEW_TYPE, fieldType) && adjustmentsAreEnabled) {
		return (
			<EnabledFieldValueDecorator fieldProps={fieldProps} fieldId={fieldId} fieldType={fieldType}>
				{children}
			</EnabledFieldValueDecorator>
		);
	}

	return undecoratedResult;
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const FieldValueDecoratorAsync = memo(
	FieldValueDecoratorAsyncNoMemo,
) as typeof FieldValueDecoratorAsyncNoMemo;
