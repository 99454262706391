import React, { useRef, useCallback, useState } from 'react';
import { AsyncSelect } from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import debouncePromise from '@atlassian/jira-platform-debounce-promise/src/ui/debounced-promise/index.tsx';
import { fetchAffectedServicesExperience } from '@atlassian/jira-servicedesk-insight-experiences/src/index.tsx';
import type { AffectedServiceOption, AffectedServicesFieldProps } from '../../common/types.tsx';
import messages from './messages.tsx';
import { fetchAffectedServices } from './utils.tsx';

const LOAD_OPTIONS_DEBOUNCE_TIMER = 300;

const AffectedServicesPickerEdit = (props: AffectedServicesFieldProps) => {
	const {
		fieldId,
		value,
		onChange,
		autoCompleteUrl,
		isInvalid,
		isDisabled,
		placeholder = '',
		isDropdownMenuFixedAndLayered,
		onCloseMenuOnScroll,
		fetchSuggestionsOnFocus = false,
		...fieldProps
	} = props;

	const { formatMessage } = useIntl();

	const [lastFetchFailed, setLastFetchFailed] = useState<boolean>(false);
	const [defaultOptions, setDefaultOptions] = useState<AffectedServiceOption[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const shouldFetchDefaultOptions = useRef<boolean>(true);

	const searchAffectedServices = async (query = ''): Promise<AffectedServiceOption[]> => {
		setLastFetchFailed(false);

		try {
			fetchAffectedServicesExperience.start();
			const affectedServices = await fetchAffectedServices(query, autoCompleteUrl);
			fetchAffectedServicesExperience.success();
			return affectedServices;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			setLastFetchFailed(true);
			fetchAffectedServicesExperience.failure();
			return [];
		}
	};

	const onFocus = async () => {
		if (
			shouldFetchDefaultOptions &&
			defaultOptions &&
			shouldFetchDefaultOptions.current &&
			!defaultOptions.length
		) {
			shouldFetchDefaultOptions.current = false;
			setIsLoading && setIsLoading(true);
			const options = await searchAffectedServices();
			setDefaultOptions && setDefaultOptions(options);
			setIsLoading && setIsLoading(false);
		}
	};

	const loadOptionsDebounced = debouncePromise(searchAffectedServices, LOAD_OPTIONS_DEBOUNCE_TIMER);

	const noOptionsMessage = useCallback(
		() => formatMessage(lastFetchFailed ? messages.failedFetch : messages.noMatches),
		[formatMessage, lastFetchFailed],
	);

	const defaultOptionsProps = () => {
		if (fetchSuggestionsOnFocus) {
			return { onFocus, isLoading, defaultOptions };
		}
		return {};
	};

	return (
		<AsyncSelect
			{...fieldProps}
			classNamePrefix="affected-services-picker"
			fieldId={fieldId}
			loadOptions={loadOptionsDebounced}
			// @ts-expect-error - TS2322 - Type '(value: AffectedServiceOption[]) => void' is not assignable to type '(value: OptionsType<AffectedServiceOption>, action: ActionMeta<AffectedServiceOption>) => void'.
			onChange={onChange}
			autoFocus={false}
			{...defaultOptionsProps()}
			isClearable={Boolean(value)}
			isMulti
			value={value}
			// @ts-expect-error - TS2322 - Type '"error" | null' is not assignable to type 'ValidationState | undefined'.
			validationState={isInvalid === true ? 'error' : null}
			noOptionsMessage={noOptionsMessage}
			placeholder={placeholder}
			isDisabled={isDisabled}
			// @ts-expect-error - TS2322 - Type '((e: ChangeEvent<HTMLElement>) => boolean) | undefined' is not assignable to type 'boolean | EventListener | undefined'.
			closeMenuOnScroll={onCloseMenuOnScroll}
			menuPosition={isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined}
			styles={defaultSelectStyles}
			data-testid="issue-field-affected-services.ui.edit.async-select"
		/>
	);
};

export default AffectedServicesPickerEdit;
