import { expVal } from '@atlassian/jira-feature-experiments';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { REQUIRED } from '@atlassian/jira-issue-create-common-types/src/common/constants/index.tsx';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	MAX_ALLOWED_LABELS,
	MAX_LABEL_SIZE,
	MAX_STRING_SIZE,
	LABELS_MAX_ALLOWED,
	LABELS_INVALID,
	FORGE_LABELS_INVALID,
	STRING_INVALID,
} from '../../utils/validators/constants.tsx';
import { LEARN_MORE_MESSAGES, LEARN_MORE_MESSAGES_WITH_ISSUE_TERM } from './constants.tsx';
import messages from './messages.tsx';

export const getLearnMoreMessage = (fieldHelpTextUrl: string): MessageDescriptor => {
	const urlSuffix = fieldHelpTextUrl.substring(fieldHelpTextUrl.lastIndexOf('#'));

	const learnMoreMessages = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
		? LEARN_MORE_MESSAGES_WITH_ISSUE_TERM
		: LEARN_MORE_MESSAGES;

	if (urlSuffix in learnMoreMessages) {
		return learnMoreMessages[urlSuffix];
	}
	return messages.fieldHelpTextLabel;
};

export const getHelpText = (fieldHelpTextUrl: string, formatMessage: FormatMessage): string => {
	const helperMessage = getLearnMoreMessage(fieldHelpTextUrl);

	return `[${formatMessage(helperMessage)}|${fieldHelpTextUrl}]`;
};

export const getErrorMessage = (
	validationError: string | boolean,
	fieldName: string,
	formatMessage: FormatMessage,
	formatNumber: (number: number | bigint) => string,
): string | boolean => {
	switch (validationError) {
		case REQUIRED: {
			return formatMessage(messages.requiredErrorMessage, { fieldName });
		}
		case LABELS_MAX_ALLOWED: {
			return formatMessage(messages.labelsMaxErrorMessage, {
				MAX_ALLOWED_LABELS: formatNumber(MAX_ALLOWED_LABELS),
			});
		}
		case LABELS_INVALID: {
			return formatMessage(messages.labelsInvalidErrorMessage, {
				MAX_LABEL_SIZE: formatNumber(MAX_LABEL_SIZE),
			});
		}
		case STRING_INVALID: {
			return formatMessage(messages.stringInvalidErrorMessage, {
				fieldName,
				MAX_STRING_SIZE: formatNumber(MAX_STRING_SIZE),
			});
		}
		case FORGE_LABELS_INVALID: {
			return formatMessage(messages.forgeLabelsInvalidErrorMessage, {
				MAX_LABEL_SIZE: formatNumber(MAX_LABEL_SIZE),
			});
		}
		default: {
			return validationError;
		}
	}
};
