import React from 'react';
import Select from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import type { VersionsProps } from './types.tsx';
import { getGroupedOptions, removeArchivedOptions } from './utils.tsx';

const Versions = (props: VersionsProps) => {
	const {
		value,
		options,
		onChange,
		placeholder = '',
		isInvalid,
		fieldId,
		isMulti,
		autoFocus = false,
		isDisabled,
		onCloseMenuOnScroll,
		isDropdownMenuFixedAndLayered,
		inputId,
		isRequired,
		classNamePrefix,
		isClearable = true,
		ariaLabel,
		ariaLabelledBy,
		openMenuOnFocus = false,
	} = props;
	const { formatMessage } = useIntl();
	const getIsClearable = () => {
		if (!isClearable) {
			return false;
		}
		return Array.isArray(value) ? value.length > 0 : Boolean(value);
	};

	return (
		<Select
			placeholder={placeholder}
			options={getGroupedOptions(removeArchivedOptions(options), formatMessage)}
			// @ts-expect-error - TS2322 - Type '"error" | null' is not assignable to type 'ValidationState | undefined'.
			validationState={isInvalid === true ? 'error' : null}
			value={value}
			fieldId={fieldId}
			isMulti={isMulti}
			menuPosition={isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined}
			// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides -- Ignored via go/DSP-18766
			styles={defaultSelectStyles}
			// @ts-expect-error - TS2322 - Type '(option: VersionOption | VersionOption[] | null) => void' is not assignable to type '(value: VersionOption | OptionsType<VersionOption> | null, action: ActionMeta<VersionOption>) => void'.
			onChange={onChange}
			autoFocus={autoFocus}
			isDisabled={isDisabled}
			closeMenuOnScroll={onCloseMenuOnScroll}
			isClearable={getIsClearable()}
			inputId={inputId}
			required={isRequired}
			classNamePrefix={classNamePrefix}
			aria-label={ariaLabel}
			aria-labelledby={ariaLabelledBy}
			openMenuOnFocus={openMenuOnFocus}
		/>
	);
};

export default Versions;
