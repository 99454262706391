import omit from 'lodash/omit';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { HardBreakDefinition } from '@atlaskit/adf-schema/schema';
import { getEmptyADF } from '@atlaskit/adf-utils/empty-adf';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { REQUIRED } from '@atlassian/jira-issue-create-common-types/src/common/constants/index.tsx';
import { MUTATION_GROUP } from './constants.tsx';
import type { JiraADFFieldInput } from './types.tsx';

const emptyADFString = JSON.stringify(getEmptyADF());

export const sanitizeADFField = (adfValue: ADF): ADF => {
	// @ts-expect-error extra properties are not a part of ADF
	if (adfValue?.overrideRequired || adfValue?.resetRequired) {
		fireErrorAnalytics({
			meta: {
				id: 'adfObjectTypeErrorIssueCreate',
				packageName: 'jiraIssueCreateModal',
				teamName: 'gryffindor',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const sanitizedValue = omit(adfValue, ['overrideRequired', 'resetRequired']) as ADF;

	return sanitizedValue;
};

export const mutateADFField = (adfValue: ADF, fieldId: string): JiraADFFieldInput | null => {
	// stringify to compare with emptyADF
	const adfValueString = JSON.stringify(adfValue);
	// emptyADF is special case where input retention should not happen
	return emptyADFString === adfValueString
		? null
		: {
				fieldId,
				mutationGroup: MUTATION_GROUP,
				richText: {
					adfValue,
				},
			};
};

export const validateDescriptionField = (
	adfValue: ADF | null | undefined,
	isRequired: boolean,
): string | undefined => {
	if (!isRequired) {
		return undefined;
	}
	const isAdfEmptyOrUndefined =
		!adfValue || JSON.stringify(adfValue) === JSON.stringify(getEmptyADF());
	if (isAdfEmptyOrUndefined) {
		return REQUIRED;
	}

	const hardBreakNodeType: HardBreakDefinition['type'] = 'hardBreak';

	// Users can bypass the required field validation for the description field by pressing shift + enter. shift + enter creates a 'hardBreak' block inside the value. If only hardBreaks exist then treat the description field as empty
	let isAnythingThereApartFromHardBreaks = false;
	if (adfValue?.content?.length > 0) {
		for (let i = 0; i < adfValue.content.length; i += 1) {
			const block = adfValue.content[i];

			// @ts-expect-error - TS2339 - Property 'content' does not exist on type 'BlockContent | LayoutSectionDefinition | CodeBlockWithMarksDefinition | ExpandWithBreakoutDefinition | ParagraphWithIndentationDefinition'.
			const blockContent = block.content;

			isAnythingThereApartFromHardBreaks = blockContent?.some(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(blockItem: any) => blockItem.type !== hardBreakNodeType,
			);

			if (isAnythingThereApartFromHardBreaks) {
				break;
			}
		}
	}

	if (isAnythingThereApartFromHardBreaks) {
		return undefined;
	}
	return REQUIRED;
};
