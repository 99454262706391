/**
 * @generated SignedSource<<0718c37ad24df3d590e58c9d613b538a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStatus = "ACTIVE" | "ARCHIVED" | "DELETED" | "%future added value";
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type projectContext_businessEntityProjectProvider_RelayProjectContextProvider$data = {
  readonly administerProject: {
    readonly canPerform: boolean;
  };
  readonly assignIssues: {
    readonly canPerform: boolean;
  };
  readonly avatar: {
    readonly large: string;
  };
  readonly createIssues: {
    readonly canPerform: boolean;
  };
  readonly deleteIssues: {
    readonly canPerform: boolean;
  };
  readonly editIssues: {
    readonly canPerform: boolean;
  };
  readonly id: string;
  readonly isFavourite: boolean;
  readonly key: string;
  readonly linkIssues: {
    readonly canPerform: boolean;
  };
  readonly name: string;
  readonly projectId: string;
  readonly projectStyle: JiraProjectStyle;
  readonly projectType: JiraProjectType;
  readonly scheduleIssues: {
    readonly canPerform: boolean;
  };
  readonly status: JiraProjectStatus;
  readonly transitionIssues: {
    readonly canPerform: boolean;
  };
  readonly viewIssues: {
    readonly canPerform: boolean;
  };
  readonly " $fragmentType": "projectContext_businessEntityProjectProvider_RelayProjectContextProvider";
};
export type projectContext_businessEntityProjectProvider_RelayProjectContextProvider$key = {
  readonly " $data"?: projectContext_businessEntityProjectProvider_RelayProjectContextProvider$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectContext_businessEntityProjectProvider_RelayProjectContextProvider">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "canPerform"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "projectContext_businessEntityProjectProvider_RelayProjectContextProvider",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "id"
      },
      "action": "THROW",
      "path": "id"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "key"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectId"
      },
      "action": "THROW",
      "path": "projectId"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraAvatar",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "large"
            },
            "action": "THROW",
            "path": "avatar.large"
          }
        ]
      },
      "action": "THROW",
      "path": "avatar"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "name"
      },
      "action": "THROW",
      "path": "name"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectType"
      },
      "action": "THROW",
      "path": "projectType"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "status"
      },
      "action": "THROW",
      "path": "status"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "projectStyle"
      },
      "action": "THROW",
      "path": "projectStyle"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "isFavourite"
      },
      "action": "THROW",
      "path": "isFavourite"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "assignIssues",
        "args": [
          {
            "kind": "Literal",
            "name": "type",
            "value": "ASSIGN_ISSUES"
          }
        ],
        "concreteType": "JiraProjectAction",
        "kind": "LinkedField",
        "name": "action",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "THROW",
            "path": "assignIssues.canPerform"
          }
        ],
        "storageKey": "action(type:\"ASSIGN_ISSUES\")"
      },
      "action": "THROW",
      "path": "assignIssues"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "administerProject",
        "args": [
          {
            "kind": "Literal",
            "name": "type",
            "value": "EDIT_PROJECT_CONFIG"
          }
        ],
        "concreteType": "JiraProjectAction",
        "kind": "LinkedField",
        "name": "action",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "THROW",
            "path": "administerProject.canPerform"
          }
        ],
        "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
      },
      "action": "THROW",
      "path": "administerProject"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "createIssues",
        "args": [
          {
            "kind": "Literal",
            "name": "type",
            "value": "CREATE_ISSUES"
          }
        ],
        "concreteType": "JiraProjectAction",
        "kind": "LinkedField",
        "name": "action",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "THROW",
            "path": "createIssues.canPerform"
          }
        ],
        "storageKey": "action(type:\"CREATE_ISSUES\")"
      },
      "action": "THROW",
      "path": "createIssues"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "deleteIssues",
        "args": [
          {
            "kind": "Literal",
            "name": "type",
            "value": "DELETE_ISSUES"
          }
        ],
        "concreteType": "JiraProjectAction",
        "kind": "LinkedField",
        "name": "action",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "THROW",
            "path": "deleteIssues.canPerform"
          }
        ],
        "storageKey": "action(type:\"DELETE_ISSUES\")"
      },
      "action": "THROW",
      "path": "deleteIssues"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "editIssues",
        "args": [
          {
            "kind": "Literal",
            "name": "type",
            "value": "EDIT_ISSUES"
          }
        ],
        "concreteType": "JiraProjectAction",
        "kind": "LinkedField",
        "name": "action",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "THROW",
            "path": "editIssues.canPerform"
          }
        ],
        "storageKey": "action(type:\"EDIT_ISSUES\")"
      },
      "action": "THROW",
      "path": "editIssues"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "linkIssues",
        "args": [
          {
            "kind": "Literal",
            "name": "type",
            "value": "LINK_ISSUES"
          }
        ],
        "concreteType": "JiraProjectAction",
        "kind": "LinkedField",
        "name": "action",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "THROW",
            "path": "linkIssues.canPerform"
          }
        ],
        "storageKey": "action(type:\"LINK_ISSUES\")"
      },
      "action": "THROW",
      "path": "linkIssues"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "scheduleIssues",
        "args": [
          {
            "kind": "Literal",
            "name": "type",
            "value": "SCHEDULE_ISSUES"
          }
        ],
        "concreteType": "JiraProjectAction",
        "kind": "LinkedField",
        "name": "action",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "THROW",
            "path": "scheduleIssues.canPerform"
          }
        ],
        "storageKey": "action(type:\"SCHEDULE_ISSUES\")"
      },
      "action": "THROW",
      "path": "scheduleIssues"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "transitionIssues",
        "args": [
          {
            "kind": "Literal",
            "name": "type",
            "value": "TRANSITION_ISSUES"
          }
        ],
        "concreteType": "JiraProjectAction",
        "kind": "LinkedField",
        "name": "action",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "THROW",
            "path": "transitionIssues.canPerform"
          }
        ],
        "storageKey": "action(type:\"TRANSITION_ISSUES\")"
      },
      "action": "THROW",
      "path": "transitionIssues"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": "viewIssues",
        "args": [
          {
            "kind": "Literal",
            "name": "type",
            "value": "VIEW_ISSUES"
          }
        ],
        "concreteType": "JiraProjectAction",
        "kind": "LinkedField",
        "name": "action",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v0/*: any*/),
            "action": "THROW",
            "path": "viewIssues.canPerform"
          }
        ],
        "storageKey": "action(type:\"VIEW_ISSUES\")"
      },
      "action": "THROW",
      "path": "viewIssues"
    }
  ],
  "type": "JiraProject"
};
})();

(node as any).hash = "98150a5607016a9ea425926244f44d6a";

export default node;
