import type { ComponentType, ReactNode } from 'react';
import { styled } from '@compiled/react';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

type Props = {
	children: ReactNode;
	minHeight?: number | string;
	maxHeight?: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CardStyleCommon = styled.div({
	backgroundColor: token('elevation.surface.raised', N0),
	borderRadius: '8px',
	marginBottom: token('space.100', '8px'),
	padding: `${token('space.150', '12px')} ${token('space.400', '32px')}`,
	boxShadow: token(
		'elevation.shadow.raised',
		'0 1px 1px rgba(9,30,66,0.25),0 0 1px 1px rgba(9,30,66,0.13)',
	),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledCard = styled(CardStyleCommon)<{
	minHeight?: string | number;
	maxHeight?: string;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minHeight: ({ minHeight }) => (minHeight !== null ? `${minHeight}px` : 'auto'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxHeight: ({ maxHeight }) => (maxHeight !== null ? `${maxHeight}px` : 'auto'),
});

// add typing to the styled component
// @ts-expect-error - TS2322 - Type 'StyledComponentClass<ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & { minHeight: string | number; }, any, ClassAttributes<...> & ... 1 more ... & { ...; }>' is not assignable to type 'ComponentType<Props>'.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Card: ComponentType<Props> = StyledCard;
