import React, { useRef, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { ErrorMessage, HelperMessage, ValidMessage } from '@atlaskit/form';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import {
	FAILED_FIELD_STATE,
	PENDING_FIELD_STATE,
	RESOLVED_FIELD_STATE,
	STORED_FIELD_STATE,
} from '@atlassian/jira-forge-controllers-inline-fields-state/src/constants.tsx';
import { useInlineField } from '@atlassian/jira-forge-controllers-inline-fields-state/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ForgeFieldDispatchButton } from './forge-field-dispatch-button/index.tsx';
import messages from './messages.tsx';

type ForgeFieldMessageProps = {
	error: string | undefined;
	fieldId: string;
	renderFieldMessage: (error?: string) => ReactNode;
};

export const ForgeFieldMessage = (props: ForgeFieldMessageProps) => {
	const failedErrorMessageAppeared = useRef(false);
	const { error, fieldId, renderFieldMessage } = props;
	const [fieldState] = useInlineField(fieldId);
	const { formatMessage } = useIntl();

	const renderErrorMessage = () => (
		<ErrorMessage>
			<MessagePartsSeparationWrapper>
				{formatMessage(messages.forgeFieldErrorTextLabel)}
			</MessagePartsSeparationWrapper>
			<ForgeFieldDispatchButton fieldId={fieldId} />
		</ErrorMessage>
	);

	const renderSuccessMessage = () => (
		<ValidMessage>{formatMessage(messages.forgeFieldSuccessTextLabel)}</ValidMessage>
	);

	const renderHelperMessage = () => (
		<HelperMessage>
			<MessagePartsSeparationWrapper>
				<Spinner size="small" />
			</MessagePartsSeparationWrapper>
			{formatMessage(messages.forgeFieldPendingTextLabel)}
		</HelperMessage>
	);

	const renderDefaultErrorMessage = () => {
		if (renderFieldMessage !== undefined && renderFieldMessage !== null) {
			return <>{renderFieldMessage(error)}</>;
		}
		return null;
	};

	if (error !== undefined) {
		return renderDefaultErrorMessage();
	}

	if (failedErrorMessageAppeared.current) {
		if (renderFieldMessage !== undefined && renderFieldMessage !== null) {
			switch (fieldState?.status) {
				case FAILED_FIELD_STATE:
					return renderErrorMessage();
				case STORED_FIELD_STATE:
					return renderSuccessMessage();
				case PENDING_FIELD_STATE:
				case RESOLVED_FIELD_STATE:
					return renderHelperMessage();
				default:
					return renderDefaultErrorMessage();
			}
		}
		return null;
	}

	if (fieldState?.status === FAILED_FIELD_STATE) {
		failedErrorMessageAppeared.current = true;
		return renderErrorMessage();
	}

	return renderDefaultErrorMessage();
};

// For now, it only gives some space between the spinner and the message
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const MessagePartsSeparationWrapper = styled.div({
	paddingRight: token('space.050', '4px'),
});
