import { useEffect, useMemo } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { REQUEST_END, REQUEST_START } from '@atlassian/jira-business-performance/src/constants.tsx';
import { markViewMetric } from '@atlassian/jira-business-performance/src/ui/page-load/utils.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type {
	JiraBusinessFormById,
	JiraBusinessFormByIdVariables,
} from './__generated_apollo__/JiraBusinessFormById';
import type {
	JiraBusinessFormWithFieldDataByFormId,
	JiraBusinessFormWithFieldDataByFormIdVariables,
} from './__generated_apollo__/JiraBusinessFormWithFieldDataByFormId';
import { JIRA_BUSINESS_FORM_BY_ID, JIRA_BUSINESS_FORM_WITH_FIELD_DATA_BY_FORM_ID } from './gql.tsx';
import type { FormNotFoundError } from './types.tsx';
import {
	transformJiraBusinessForm,
	transformFormDataWithFieldData,
	isFormNotFoundError,
} from './utils.tsx';

/**
 * This is used for form builder to get the bare minimum form data without fields
 */
export const useFormById = (formId?: string | null) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [fetch, { data, loading, error }] = useLazyQuery<
		JiraBusinessFormById,
		JiraBusinessFormByIdVariables
	>(JIRA_BUSINESS_FORM_BY_ID);

	useEffect(() => {
		if (formId != null) {
			markViewMetric('form', REQUEST_START);

			const QUERY_NAME = 'JiraBusinessFormById';

			fetch({
				variables: {
					formId,
					isConditionalFieldsEnabled: fg('jira_forms_conditional_field_logic_gate_'),
				},
				// @ts-expect-error - TS2345 - Argument of type '{ variables: { formId: string; }; onError: (apolloError: any) => void; onCompleted: () => void; }' is not assignable to parameter of type 'QueryLazyOptions<JiraBusinessFormByIdVariables>'. | TS7006 - Parameter 'apolloError' implicitly has an 'any' type.
				onError: (apolloError) => {
					fireErrorAnalytics({
						meta: {
							id: QUERY_NAME,
							packageName: 'jiraBusinessForm',
							teamName: 'jira-werewolves',
						},
						error: apolloError,
						sendToPrivacyUnsafeSplunk: true,
					});
					markViewMetric('form', REQUEST_END);
				},
				onCompleted: () => {
					fireOperationalAnalytics(createAnalyticsEvent({}), `${QUERY_NAME} succeeded`);
					markViewMetric('form', REQUEST_END);
				},
			});
		}
	}, [createAnalyticsEvent, fetch, formId]);

	const form = useMemo(() => transformJiraBusinessForm({ data }), [data]);

	return { form, loading, error };
};

/**
 * This is used for form submission
 */
export const useGetFormWithFieldDataByFormId = (formId: string) => {
	const QUERY_NAME = 'JiraBusinessFormWithFieldDataByFormId';
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		markViewMetric('form-submit', REQUEST_START);
	}, []);

	const { data, loading, error } = useQuery<
		JiraBusinessFormWithFieldDataByFormId,
		JiraBusinessFormWithFieldDataByFormIdVariables
	>(JIRA_BUSINESS_FORM_WITH_FIELD_DATA_BY_FORM_ID, {
		/**
		 * Forms currently has no requirement to utilize a cache, however if
		 * this policy is changed, be aware that you may need to make other changes
		 * to ensure that GQL unions work as expected and/or the cache doesn't
		 * collide with other features.
		 * See: https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/pull-requests/45683/overview?commentId=2977330
		 */
		fetchPolicy: 'no-cache',
		variables: {
			formId,
			isConditionalFieldsEnabled: fg('jira_forms_conditional_field_logic_gate_'),
		},
		onError: (apolloError) => {
			const analyticsError = apolloError;
			const skipSentry = isFormNotFoundError(analyticsError);
			if (skipSentry) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(analyticsError as FormNotFoundError).skipSentry = true;
			}
			fireErrorAnalytics({
				meta: {
					id: QUERY_NAME,
					packageName: 'jiraBusinessForm',
					teamName: 'jira-werewolves',
				},
				error: analyticsError,
				sendToPrivacyUnsafeSplunk: true,
			});
			markViewMetric('form-submit', REQUEST_END);
		},
		onCompleted: () => {
			fireOperationalAnalytics(createAnalyticsEvent({}), `${QUERY_NAME} succeeded`);
			markViewMetric('form-submit', REQUEST_END);
		},
	});

	return { data: transformFormDataWithFieldData({ data }), loading, error };
};
