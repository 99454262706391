import React from 'react';
import SelectField, {
	type SelectFieldProps,
} from '../../common/ui/fields/base-fields/select-field/index.tsx';

const SingleSelectField = (props: SelectFieldProps) => (
	<SelectField {...props} isMulti={false} closeMenuOnSelect />
);

export default SingleSelectField;
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { mutateSingleSelectField, mapSelectOptionsToConnectData } from './utils';
