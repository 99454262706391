import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	AFFECTS_VERSIONS_TYPE,
	ASSIGNEE_TYPE,
	ATTACHMENT_TYPE,
	CASCADING_SELECT_CF_TYPE,
	CATEGORY_TYPE,
	COMPONENTS_TYPE,
	DATETIME_CF_TYPE,
	DATE_CF_TYPE,
	DESCRIPTION_TYPE,
	DUE_DATE_TYPE,
	EPIC_COLOR_TYPE,
	EPIC_NAME_TYPE,
	EPIC_TYPE,
	FIX_VERSIONS_TYPE,
	GROUP_CF_TYPE,
	LABELS_CF_TYPE,
	LABELS_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	MULTI_USER_CF_TYPE,
	MULTI_VERSION_CF_TYPE,
	NUMBER_CF_TYPE,
	PARENT_TYPE,
	PEOPLE_CF_TYPE,
	PRIORITY_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	SELECT_CF_TYPE,
	SERVICE_ENTITY_CF_TYPE,
	SPRINT_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	SUMMARY_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
	TEAM_CF_TYPE,
	TEXT_AREA_CF_TYPE,
	TEXT_CF_TYPE,
	URL_CF_TYPE,
	USER_CF_TYPE,
	VERSION_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { AffectedServicesCustomFieldTypeConfig } from './field-types/affected-services-custom-field-type.tsx';
import { AssigneeFieldTypeConfig } from './field-types/assignee-field-type.tsx';
import { AttachmentFieldTypeConfig } from './field-types/attachment-field-type.tsx';
import { CascadingSelectFieldTypeConfig } from './field-types/cascading-select-custom-field-type.tsx';
import { CategoryFieldTypeConfig } from './field-types/category-field-type.tsx';
import { ComponentsFieldTypeConfig } from './field-types/components-field-type.tsx';
import { DateCustomFieldTypeConfig } from './field-types/date-custom-field-type.tsx';
import { DateTimeCustomFieldTypeConfig } from './field-types/date-time-custom-field-type.tsx';
import { DescriptionFieldTypeConfig } from './field-types/description-field-type.tsx';
import { DueDateFieldTypeConfig } from './field-types/due-date-field-type.tsx';
import { GroupCustomFieldTypeConfig } from './field-types/group-custom-field-type.tsx';
import { LabelsCustomFieldTypeConfig } from './field-types/labels-custom-field-type.tsx';
import { LabelsFieldTypeConfig } from './field-types/labels-field-type.tsx';
import { MultiCheckboxesCustomFieldTypeConfig } from './field-types/multi-checkboxes-custom-field-type.tsx';
import { MultiGroupCustomFieldTypeConfig } from './field-types/multi-group-custom-field-type.tsx';
import { MultiSelectCustomFieldTypeConfig } from './field-types/multi-select-custom-field-type.tsx';
import { MultiUserCustomFieldTypeConfig } from './field-types/multi-user-custom-field-type.tsx';
import { NumberCustomFieldTypeConfig } from './field-types/number-custom-field-type.tsx';
import { ParentFieldTypeConfig } from './field-types/parent-field-type.tsx';
import { PeopleCustomFieldType } from './field-types/people-custom-field-type.tsx';
import { PriorityFieldTypeConfig } from './field-types/priority-field-type.tsx';
import { RadioButtonsCustomFieldTypeConfig } from './field-types/radio-buttons-custom-field-type.tsx';
import { SelectCustomFieldTypeConfig } from './field-types/select-custom-field-type.tsx';
import { SprintFieldTypeConfig } from './field-types/sprint-field-type.tsx';
import { StoryPointEstimateCustomFieldTypeConfig } from './field-types/story-point-estimate-custom-field-type.tsx';
import { SummaryFieldTypeConfig } from './field-types/summary-field-type.tsx';
import { TeamCustomFieldTypeConfig } from './field-types/team-custom-field-type.tsx';
import { TeamsPlatformCustomFieldTypeConfig } from './field-types/teams-platform-custom-field-type.tsx';
import { TextAreaCustomFieldTypeConfig } from './field-types/text-area-custom-field-type.tsx';
import { TextCustomFieldTypeConfig } from './field-types/text-custom-field-type.tsx';
import { UrlCustomFieldTypeConfig } from './field-types/url-custom-field-type.tsx';
import { UserCustomFieldTypeConfig } from './field-types/user-custom-field-type.tsx';
import {
	VersionFieldTypeConfig,
	MultiVersionsFieldTypeConfig,
} from './field-types/versions-field-type.tsx';
import type {
	FieldTypeConfig,
	SoftwareSupportedFieldType,
	SupportedFieldType,
	BaseSupportedFieldType,
	ProjectScopedSupportedFieldType,
} from './types.tsx';

const PROJECT_SCOPED_FIELD_TYPE_CONFIGS: Record<
	ProjectScopedSupportedFieldType,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FieldTypeConfig<any, any, any>
> = {
	[PARENT_TYPE]: ParentFieldTypeConfig,
	[SPRINT_TYPE]: SprintFieldTypeConfig,
};

const BASE_FIELD_TYPE_CONFIGS: Record<
	BaseSupportedFieldType,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FieldTypeConfig<any, any, any>
> = {
	[AFFECTS_VERSIONS_TYPE]: MultiVersionsFieldTypeConfig(AFFECTS_VERSIONS_TYPE),
	[ASSIGNEE_TYPE]: AssigneeFieldTypeConfig,
	[ATTACHMENT_TYPE]: AttachmentFieldTypeConfig,
	[CASCADING_SELECT_CF_TYPE]: CascadingSelectFieldTypeConfig,
	[CATEGORY_TYPE]: CategoryFieldTypeConfig,
	[COMPONENTS_TYPE]: ComponentsFieldTypeConfig,
	[DATETIME_CF_TYPE]: DateTimeCustomFieldTypeConfig,
	[DATE_CF_TYPE]: DateCustomFieldTypeConfig,
	[DESCRIPTION_TYPE]: DescriptionFieldTypeConfig,
	[DUE_DATE_TYPE]: DueDateFieldTypeConfig,
	[FIX_VERSIONS_TYPE]: MultiVersionsFieldTypeConfig(FIX_VERSIONS_TYPE),
	[GROUP_CF_TYPE]: GroupCustomFieldTypeConfig,
	[LABELS_CF_TYPE]: LabelsCustomFieldTypeConfig,
	[LABELS_TYPE]: LabelsFieldTypeConfig,
	[MULTI_CHECKBOXES_CF_TYPE]: MultiCheckboxesCustomFieldTypeConfig,
	[MULTI_GROUP_CF_TYPE]: MultiGroupCustomFieldTypeConfig,
	[MULTI_SELECT_CF_TYPE]: MultiSelectCustomFieldTypeConfig,
	[MULTI_USER_CF_TYPE]: MultiUserCustomFieldTypeConfig,
	[MULTI_VERSION_CF_TYPE]: MultiVersionsFieldTypeConfig(MULTI_VERSION_CF_TYPE),
	[NUMBER_CF_TYPE]: NumberCustomFieldTypeConfig,
	[PARENT_TYPE]: ParentFieldTypeConfig,
	[PEOPLE_CF_TYPE]: PeopleCustomFieldType,
	[PRIORITY_TYPE]: PriorityFieldTypeConfig,
	[RADIO_BUTTONS_CF_TYPE]: RadioButtonsCustomFieldTypeConfig,
	[SELECT_CF_TYPE]: SelectCustomFieldTypeConfig,
	[SERVICE_ENTITY_CF_TYPE]: AffectedServicesCustomFieldTypeConfig,
	[STORY_POINT_ESTIMATE_CF_TYPE]: StoryPointEstimateCustomFieldTypeConfig,
	[SUMMARY_TYPE]: SummaryFieldTypeConfig,
	[TEAMS_PLATFORM_CF_TYPE]: TeamsPlatformCustomFieldTypeConfig,
	[TEAM_CF_TYPE]: TeamCustomFieldTypeConfig,
	[TEXT_AREA_CF_TYPE]: TextAreaCustomFieldTypeConfig,
	[TEXT_CF_TYPE]: TextCustomFieldTypeConfig,
	[URL_CF_TYPE]: UrlCustomFieldTypeConfig,
	[USER_CF_TYPE]: UserCustomFieldTypeConfig,
	[VERSION_CF_TYPE]: VersionFieldTypeConfig,
};

const SOFTWARE_FIELD_TYPE_CONFIGS: Record<
	SoftwareSupportedFieldType,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FieldTypeConfig<any, any, any>
> = {
	[SPRINT_TYPE]: SprintFieldTypeConfig,
};

const FIELD_TYPE_CONFIGS: Record<
	BaseSupportedFieldType | SoftwareSupportedFieldType,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FieldTypeConfig<any, any, any>
> = {
	...BASE_FIELD_TYPE_CONFIGS,
	...SOFTWARE_FIELD_TYPE_CONFIGS,
};

export function isFieldTypeSupported(
	type: string,
	projectType?: string,
): type is SupportedFieldType {
	if (projectType === SOFTWARE_PROJECT) {
		return type in BASE_FIELD_TYPE_CONFIGS || type in SOFTWARE_FIELD_TYPE_CONFIGS;
	}

	return type in BASE_FIELD_TYPE_CONFIGS;
}

export function getFieldTypeConfig(fieldType: string, projectType?: string) {
	return isFieldTypeSupported(fieldType, projectType) ? FIELD_TYPE_CONFIGS[fieldType] : undefined;
}

// Delete FORM_LABEL on jsw_forms_rollout FG cleanup
export const FORM_LABEL = 'business-form';

export const getFormLabelPrefix = (projectType: string | undefined) => {
	if (fg('fix_forms_issue_create_labelling_issue')) {
		if (projectType === SOFTWARE_PROJECT) {
			return 'form';
		}
		return 'business-form';
	}
	if (projectType != null && projectType === 'business') {
		return 'business-form';
	}
	return 'form';
};

export const getUniqueFormLabel = (formLabelPrefix: string, formId: string) =>
	`${formLabelPrefix}-${formId}`;

export const isProjectScopedFieldType = (type: string) => type in PROJECT_SCOPED_FIELD_TYPE_CONFIGS;

/**
 * List of fields IDs that do not make sense to be shown in form
 * This is based on https://hello.atlassian.net/wiki/spaces/JBus/pages/988271414/Forms+-+Field+support+prioritisation Quadrant 4
 */
const improperFieldIdsForForm = new Set([
	'created',
	'issuelinks',
	'issuetype',
	'project',
	'resolution',
	'security',
	'subtasks',
	'timeoriginalestimate',
	'updated',
	'updated',
	'votes',
	'watches',
]);

const improperFieldTypesForForm = new Set<string>([EPIC_TYPE, EPIC_COLOR_TYPE, EPIC_NAME_TYPE]);

export const isFieldImproperForForm = ({
	fieldId,
	fieldName,
	fieldType,
}: {
	fieldId: string;
	fieldName: string;
	fieldType: string;
}) => {
	if (improperFieldIdsForForm.has(fieldId) || improperFieldTypesForForm.has(fieldType)) {
		return true;
	}

	// Cases for others where fieldId and fieldType are not sufficient
	// and requires combination of both
	// e.g. for some custom field because their fieldId is different in every instance

	// Flagged field
	if (fieldName === 'Flagged' && fieldType === MULTI_CHECKBOXES_CF_TYPE) {
		return true;
	}

	return false;
};
