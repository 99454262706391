import React, { useMemo, type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';
import { AriProvider } from '@atlassian/jira-business-entity-common/src/controllers/ari-context/index.tsx';
import { BusinessEntityProvider } from '@atlassian/jira-business-entity-common/src/controllers/business-entity-context/index.tsx';
import { JqlProvider } from '@atlassian/jira-business-entity-common/src/controllers/jql-context/index.tsx';
import { RealtimeProvider } from '@atlassian/jira-business-entity-common/src/controllers/realtime-context/index.tsx';
import type { JiraProject } from '@atlassian/jira-business-entity-common/src/types.tsx';
import { ProjectHookProvider } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import {
	ExperienceStart,
	ExperienceSuccess,
	ExperienceFailed,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { FiltersProvider } from '@atlassian/jira-business-filters/src/controllers/filters-context/index.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import type { projectContext_businessEntityProjectProvider_RelayProjectContextProvider$key } from '@atlassian/jira-relay/src/__generated__/projectContext_businessEntityProjectProvider_RelayProjectContextProvider.graphql';
// eslint-disable-next-line camelcase
import { useProject_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { FETCH_PROJECT_EXPERIENCE } from '../../constants.tsx';
import NotFound from './not-found/index.tsx';

type Props = {
	children: ReactNode;
	projectFragment:
		| projectContext_businessEntityProjectProvider_RelayProjectContextProvider$key
		| null
		| undefined;
};

export const RelayProjectContextProvider = ({ children, projectFragment = null }: Props) => {
	const { replace } = useRouterActions();

	const projectData = useFragment(
		graphql`
			fragment projectContext_businessEntityProjectProvider_RelayProjectContextProvider on JiraProject {
				id @required(action: THROW)
				key @required(action: THROW)
				projectId @required(action: THROW)
				avatar @required(action: THROW) {
					large @required(action: THROW)
				}
				name @required(action: THROW)
				projectType @required(action: THROW)
				status @required(action: THROW)
				projectStyle @required(action: THROW)
				isFavourite @required(action: THROW)
				assignIssues: action(type: ASSIGN_ISSUES) @required(action: THROW) {
					canPerform @required(action: THROW)
				}
				administerProject: action(type: EDIT_PROJECT_CONFIG) @required(action: THROW) {
					canPerform @required(action: THROW)
				}
				createIssues: action(type: CREATE_ISSUES) @required(action: THROW) {
					canPerform @required(action: THROW)
				}
				deleteIssues: action(type: DELETE_ISSUES) @required(action: THROW) {
					canPerform @required(action: THROW)
				}
				editIssues: action(type: EDIT_ISSUES) @required(action: THROW) {
					canPerform @required(action: THROW)
				}
				linkIssues: action(type: LINK_ISSUES) @required(action: THROW) {
					canPerform @required(action: THROW)
				}
				scheduleIssues: action(type: SCHEDULE_ISSUES) @required(action: THROW) {
					canPerform @required(action: THROW)
				}
				transitionIssues: action(type: TRANSITION_ISSUES) @required(action: THROW) {
					canPerform @required(action: THROW)
				}
				viewIssues: action(type: VIEW_ISSUES) @required(action: THROW) {
					canPerform @required(action: THROW)
				}
			}
		`,
		projectFragment,
	);

	const project: JiraProject | null = useMemo(
		() =>
			projectData
				? {
						id: Number(projectData.projectId),
						key: projectData.key,
						avatarUrl: projectData.avatar.large,
						name: projectData.name,
						type: projectData.projectType.toLowerCase(),
						isSimplified: projectData.projectStyle === 'TEAM_MANAGED_PROJECT',
						isFavorite: projectData.isFavourite,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						status: projectData.status as JiraProject['status'],
						permissions: {
							assignIssues: projectData.assignIssues.canPerform,
							administerProject: projectData.administerProject.canPerform,
							createIssues: projectData.createIssues.canPerform,
							deleteIssues: projectData.deleteIssues.canPerform,
							editIssues: projectData.editIssues.canPerform,
							linkIssues: projectData.linkIssues.canPerform,
							scheduleIssues: projectData.scheduleIssues.canPerform,
							transitionIssues: projectData.transitionIssues.canPerform,
							viewIssues: projectData.viewIssues.canPerform,
						},
					}
				: null,
		[projectData],
	);

	const jqlContext = projectData ? `project = '${projectData.key}'` : '';

	const analyticsAttributes = useMemo(
		() => ({
			projectId: Number(projectData?.projectId),
			isTeamManaged: projectData?.projectStyle === 'TEAM_MANAGED_PROJECT',
			projectType: projectData?.projectType.toLowerCase(),
		}),
		[projectData],
	);

	const entityData = useMemo(
		() =>
			projectData?.viewIssues.canPerform
				? {
						name: projectData.name,
						id: String(projectData.projectId),
					}
				: null,
		[projectData],
	);

	const projects = useMemo(() => (project ? [project] : undefined), [project]);
	const projectIdList = useMemo(
		() => (projectData ? [Number(projectData.projectId)] : undefined),
		[projectData],
	);
	if (projectData?.status === 'ARCHIVED') {
		replace('/jira/errors/project-archived');
		return null;
	}

	let content;

	if (projectData == null || project == null || entityData == null) {
		content = <NotFound />;
	} else {
		content = (
			<ProjectHookProvider project={project}>
				<BusinessEntityProvider data={entityData}>
					<RealtimeProvider ids={projectIdList}>
						<AriProvider ari={projectData.id}>
							<JqlProvider jqlContext={jqlContext}>
								<FiltersProvider projects={projects}>{children}</FiltersProvider>
							</JqlProvider>
						</AriProvider>
					</RealtimeProvider>
					<ExperienceSuccess experience={FETCH_PROJECT_EXPERIENCE} />
				</BusinessEntityProvider>
			</ProjectHookProvider>
		);
	}

	return (
		<ContextualAnalyticsData
			containerId={projectData?.projectId}
			containerType="project"
			sourceType={SCREEN}
			sourceName="businessProjectContext"
			attributes={analyticsAttributes}
			// we set a key on the first element to force React to re-render when the context changes
			// to avoid query race-conditions that would lead to promises from a previous context
			// resolving in the current context view, and thus seeing unrelated data
			key={projectData?.projectId}
		>
			{content}
		</ContextualAnalyticsData>
	);
};

/**
 * @deprecated Do not use this provider. Instead use RelayProjectContextProvider.
 */
const ProjectContextProvider = ({ children }: { children: ReactNode }) => {
	const { data: project, loading, error } = useProject_DEPRECATED_DO_NOT_USE();
	const { replace } = useRouterActions();
	const cloudId = useCloudId();

	const projectId = project?.id;
	const projectKey = project?.key;
	const projectType = project?.type;
	const isTeamManaged = Boolean(project?.isSimplified);

	const jqlContext = projectKey ? `project = '${projectKey}'` : '';

	const analyticsAttributes = useMemo(
		() => ({
			projectId,
			isTeamManaged,
			projectType,
		}),
		[projectId, isTeamManaged, projectType],
	);

	const entityData = useMemo(
		() =>
			project?.permissions?.viewIssues
				? {
						name: project.name,
						id: String(project.id),
					}
				: null,
		[project],
	);

	const projects = useMemo(() => (project ? [project] : undefined), [project]);
	const projectIdList = useMemo(() => (projectId ? [projectId] : undefined), [projectId]);
	const projectAri = `ari:cloud:jira:${cloudId}:project/${projectId}`;

	if (project?.status === 'ARCHIVED') {
		replace('/jira/errors/project-archived');
		return null;
	}

	let content;

	if (loading) {
		content = <ThemedLazySkeleton />;
	} else if (project == null || entityData == null || error) {
		content = (
			<>
				<NotFound />
				{error && (
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
					<ExperienceFailed experience={FETCH_PROJECT_EXPERIENCE} error={error as any} />
				)}
			</>
		);
	} else {
		content = (
			<ProjectHookProvider project={project}>
				<BusinessEntityProvider data={entityData}>
					<RealtimeProvider ids={projectIdList}>
						<AriProvider ari={projectAri}>
							<JqlProvider jqlContext={jqlContext}>
								<FiltersProvider projects={projects}>{children}</FiltersProvider>
							</JqlProvider>
						</AriProvider>
					</RealtimeProvider>
					<ExperienceSuccess experience={FETCH_PROJECT_EXPERIENCE} />
				</BusinessEntityProvider>
			</ProjectHookProvider>
		);
	}

	return (
		<ContextualAnalyticsData
			containerId={String(project?.id)}
			containerType="project"
			sourceType={SCREEN}
			sourceName="businessProjectContext"
			attributes={analyticsAttributes}
			// we set a key on the first element to force React to re-render when the context changes
			// to avoid query race-conditions that would lead to promises from a previous context
			// resolving in the current context view, and thus seeing unrelated data
			key={String(project?.id)}
		>
			<ExperienceStart experience={FETCH_PROJECT_EXPERIENCE} />
			{content}
		</ContextualAnalyticsData>
	);
};

/**
 * @deprecated Do not use this provider. Instead use RelayProjectContextProvider.
 */
export default ProjectContextProvider;
