import React, { memo, type ComponentType } from 'react';
import '@atlaskit/css-reset';
import type { EntryPointProps } from 'react-relay';
import AppBase from '@atlassian/jira-app-base/src/index.tsx';
import { FormSubmissionContextProvider } from '@atlassian/jira-business-form/src/ui/form-submission/form-submission-context-provider/index.tsx';
import { FormSubmissionRoot } from '@atlassian/jira-business-form/src/ui/form-submission/index.tsx';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { AuthenticationCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

type JiraBusinessFormAppType = EntryPointProps<{}, {}, {}, {}>;

const JiraBusinessFormApp: ComponentType<JiraBusinessFormAppType> = () => (
	<UFOSegment name="business-form-submit-app">
		<AuthenticationCheck>
			<AppBase id="business">
				<FormSubmissionContextProvider>
					<FormSubmissionRoot />
				</FormSubmissionContextProvider>
				<SubProductUpdater subProduct="core" />
			</AppBase>
		</AuthenticationCheck>
	</UFOSegment>
);

export default memo(JiraBusinessFormApp);
