import type { Maybe, MutationErrorExtension, MutationErrorPayload } from './types.tsx';

export class MutationError extends Error {
	extensions: Maybe<MutationErrorExtension>;

	statusCode: number | null;

	errorType: string | null;

	constructor(payload: MutationErrorPayload) {
		super(payload.message ?? undefined);
		this.message = payload.message ?? '';
		this.extensions = payload.extensions;
		this.statusCode = payload.extensions?.statusCode ?? null;
		this.errorType = payload.extensions?.errorType ?? null;
	}
}
