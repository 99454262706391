import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	description: {
		id: 'issue-create-commons.parent-field.description',
		defaultMessage: 'Your issue type hierarchy determines the issues you can select here.',
		description: 'This is a description for the parent field',
	},
	descriptionIssueTermRefresh: {
		id: 'issue-create-commons.parent-field.description-issue-term-refresh',
		defaultMessage: 'Your work type hierarchy determines the work items you can select here.',
		description: 'This is a description for the parent field',
	},
});
