/* eslint-disable camelcase */
import type { JiraBusinessFormById_jiraBusinessForm_fields_conditions } from '../../../services/get-form/__generated_apollo__/JiraBusinessFormById';

/**
 * An empty condition is one in which the values necessary to
 * determining the outcome of a condition have not been set.
 * For example in the case of select-based fields, this refers to
 * condition with no field option IDs set.
 */
export const isConditionEmpty = (
	condition: JiraBusinessFormById_jiraBusinessForm_fields_conditions,
) => {
	if (condition.__typename === 'JiraFormSelectCondition') {
		return !condition.fieldOptionIds?.length;
	}
	return true;
};
