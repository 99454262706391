import React, { useMemo, useState, useEffect, type ReactNode } from 'react';
import client from '@atlassian/jira-apollo-gira/src/index.tsx';
import { ApolloClientProvider } from '@atlassian/jira-apollo-multiple-clients/src/main.tsx';
import ProjectContextProvider from '@atlassian/jira-business-entity-project-provider/src/ui/project-context/index.tsx';
import {
	ExperienceFailed,
	ExperienceErrorBoundary,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { CORE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
// eslint-disable-next-line camelcase
import { useProject_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { VIEW_FORM_SUBMISSION_EXPERIENCE } from '../../../common/constants.tsx';
import { FormAccessLevelTypes } from '../../../common/types.tsx';
import { FormUnavailableView } from '../../../common/ui/empty-views/index.tsx';
import { useConditionalFieldLogicAnalyticsAttrs } from '../../../common/utils/conditional-field-logic-gate/index.tsx';
import { FormSubmissionPreloader } from '../../../controllers/form-submission/index.tsx';
import { useFormSubmissionStore } from '../../../controllers/form-submission/store/index.tsx';
import { isFormNotFoundError } from '../../../services/get-form/utils.tsx';

type Props = {
	children: ReactNode;
};

const FormAccessWrapper = ({ children }: Props) => {
	const [initialLoading, setInitialLoading] = useState(true);
	const { data: project, loading: projectLoading } = useProject_DEPRECATED_DO_NOT_USE();

	const [{ data, loading, error }] = useFormSubmissionStore();

	useEffect(() => {
		if (loading) {
			setInitialLoading(false);
		}
	}, [loading]);

	const isOpenForm = data?.accessLevel === FormAccessLevelTypes.OPEN;
	const projectId = String(data?.projectId);
	const analyticsAttributes = useMemo(
		() => ({ projectId, isOpenForm, projectType: CORE_PROJECT }),
		[projectId, isOpenForm],
	);
	const experienceTrackerAttributes = useConditionalFieldLogicAnalyticsAttrs();

	if (initialLoading || projectLoading || loading) {
		return <ThemedLazySkeleton />;
	}

	if (error) {
		return (
			<>
				{!isFormNotFoundError(error) && (
					<ExperienceFailed
						experience={VIEW_FORM_SUBMISSION_EXPERIENCE}
						error={error}
						attributes={experienceTrackerAttributes}
					/>
				)}
				<FormUnavailableView />
			</>
		);
	}

	if (
		(!project || !project.permissions.viewIssues || !project.permissions.createIssues) &&
		isOpenForm
	) {
		return (
			<ContextualAnalyticsData
				containerId={projectId}
				containerType="project"
				sourceType={SCREEN}
				sourceName="businessFormSubmissionContext"
				attributes={analyticsAttributes}
			>
				{children}
			</ContextualAnalyticsData>
		);
	}

	if (fg('sv-33_deprecate_business_project_context_provider')) {
		return children;
	}

	return <ProjectContextProvider>{children}</ProjectContextProvider>;
};

export const FormSubmissionContextProvider = ({ children }: Props) => (
	<ExperienceErrorBoundary experience={VIEW_FORM_SUBMISSION_EXPERIENCE} shouldStartExperience>
		<ApolloClientProvider client={client}>
			<FormSubmissionPreloader />
			<FormAccessWrapper>{children}</FormAccessWrapper>
		</ApolloClientProvider>
	</ExperienceErrorBoundary>
);
